<template>
    <div>
        <div class="topbox">
            <div class="topcont flex align-center justify-between">
                <div class="flex align-center">
                    <el-avatar shape="square" :size="96" :src="companyInfo.logo?companyInfo.logo:squareUrl"></el-avatar>
                    <div class="topleftCont flex flex-direction justify-between">
                        <strong class="text-black text-xxl">{{ companyInfo?.companyName }}</strong>
                        <div class="flex align-center">{{ companyInfo?.companyNatureName }} | {{ companyInfo?.companyScale }} |
                            {{ companyInfo?.companyIndustryName }}</div>
                    </div>
                </div>
                <div class="flex align-center text-xl"><strong style="font-size: 30px;color: #298dff; margin-right: 5px;">{{jobTotal}}</strong>个招聘大厅</div>
            </div>
        </div>
        <div v-loading="loading">
            <div class="newjobCont flex align-center flex-wrap" >
                <div class="newjobItem text-white flex flex-direction justify-between" v-for="item in jobList">
                    <div class="padding-sm margin-top-xs">
                        <div class="text-xl margin-bottom-sm">{{ item.workName }}</div>
                        <div class="text-df margin-top-xs"><span style="opacity: 0.8; margin-right: 6px;">薪资</span><span>{{ item.wagesName }}</span></div>
                        <div class="text-df margin-top-xs"><span style="opacity: 0.8; margin-right: 6px;">学历</span><span>{{ item.workEducation }}</span></div>
                        <div class="text-df margin-top-xs"><span style="opacity: 0.8; margin-right: 6px;">年限</span><span>{{ item.workYears }}</span></div>
                        <div class="address text-df margin-top-xs"><span style="opacity: 0.8;margin-right: 6px;">地点</span><span>{{ item.workAreaName }}</span></div>
                        <div class="gotoBtn flex align-center justify-center cursor-pointer" style="margin-top: 30px;" @click="gotoDating(item)">招聘大厅人数：{{ item.joinUserNumber }}/12人</div>
                    </div>
                </div>
            </div>
            <div class="pagebox">
                <app-pagination
                    @handleCurrentChange="handleCurrentChange"
                    :pageSize="searchForm.pageSize"
                    :total="total"
                ></app-pagination>
            </div>

        </div>
    </div>
</template>

<script>
import userRequest from '../../../api/user'
import AppPagination from "../../../components/pagination/AppPagination";
export default{
    components:{
        AppPagination,
    },
    data(){
        return{
            companyId:null,
            loading:false,
            squareUrl:'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png',
            list:[],
            searchForm:{
                pageSize: 12,
                pageNum: 1,
            },
            total:0,
            companyInfo: {},
            jobList: [],
            jobTotal: 0
        }
    },
    created() {
        let query = this.$route.query
        if (!query.companyId) {
            this.$router.back()
            return
        }
        this.companyId = query.companyId

        this.getCompanyDetail()
        this.getJobListByCompanyId()

    },
    methods:{
        getCompanyDetail() {
            userRequest.getCompanyDetail({memberId: this.companyId}).then((res) => {
                if (res.code === 200) {
                    this.companyInfo = res.data
                }
            })
        },
        getJobListByCompanyId() {
            userRequest.getJobListByCompanyId({memberId:this.companyId,...this.searchForm}).then((res) => {
                if (res.code === 200) {
                    this.jobList = res.data.list
                    this.jobTotal = res.data.total
                }
            })
        },
        handleCurrentChange(num) {

            this.searchForm.pageNum = num;
            this.getJobListByCompanyId();
        },
        gotoDating(item){//去大厅界面
            if(item.joinUserNumber>=8){
                this.$message.warning("招聘大厅人数已满！")
                return
            }

            const {href} = this.$router.resolve({
                path: '/user/tempInterview',
                query: {
                    workId: item.id,
                }
            })
            window.open(href, '_blank')
        },
    }
}
</script>
<style scoped>
.topbox{ height: 154px;background: linear-gradient(90deg, rgba(22, 120, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);}
.topcont{ width: 1200px; margin: 0 auto 0 auto; padding-top: 25px;}
.topleftCont{ height: 70px; margin-left: 12px;}
.newjobbox{height: calc(100vh - 200px); overflow-y: auto;}
.newjobCont{width: 1200px; margin: 0 auto 0 auto; padding-top: 20px;}
.newjobItem{ width: 285px; height: 253px; margin-bottom: 20px; margin-right: 20px; border-radius: 6px; overflow: hidden; background: url("../../../assets/jobbg.png") no-repeat top center;}
.newjobItem:nth-child(4n){ margin-right: 0;}
.gotoBtn{border-radius: 24px;
    margin-top: 10px;
    background: rgba(56, 133, 242, 1);
    box-shadow:inset 1px 2px 4px 0px rgba(6, 83, 191, 1);
    backdrop-filter: blur(2px); height: 36px; font-size: 17px;}

</style>



